import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

//Import Components
import ButtonWithCircle from '../components/ButtonWithCircle';
import Label from '../components/Label';
import TitleUnderlined from '../components/TitleUnderlined';
import ButtonUnderlined from '../components/ButtonUnderlined';
import TypingTest from '../blocks/typingText';
import StatsBlock from '../blocks/statsBlock';

const TestGround = () => (
  <Layout>
    <SEO title="Our Expertise" />
    <TypingTest />
  </Layout>
);

export default TestGround;
